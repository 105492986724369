import React from "react";
import { useEffect, useState } from "react";
import { navigate } from "gatsby";
import Prism from "prismjs";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Pulse from "../components/pulse";

const BackIsForward = () => {
  const [page1, setPage1] = useState(false);
  const [hide, setHide] = useState(false);
  const [addListener, setAddListener] = useState(false);

  useEffect(() => Prism.highlightAll());

  useEffect(() => {
    if (page1) {
      window.history.pushState(null, "", "?page=1");
    }
  }, [page1]);

  useEffect(() => {
    if (hide) {
      window.history.pushState(null, "", "/back-is-forward");
    }
  }, [hide]);

  useEffect(() => {
    if (addListener) {
      const popstate = () => {
        navigate("/up-is-down#top");
      };

      window.addEventListener("popstate", popstate);
      return () => window.removeEventListener("popstate", popstate);
    }
  }, [addListener]);

  return (
    <Layout>
      <SEO title={"Back is Forward"} />
      <h1>1. Back is Forward</h1>

      <p>Did you know? Websites can edit the URL however they please:</p>

      <p>
        <Pulse active={page1}>
          <pre>
            <code class="language-javascript">{`window.history.pushState(null, "", "?page=1");`}</code>
          </pre>
        </Pulse>
        {!page1 && <button onClick={() => setPage1(true)}>Run</button>}
      </p>

      <p>
        <Pulse active={hide}>
          <pre>
            <code class="language-javascript">{`window.history.pushState(null, "", "/back-is-forward");`}</code>
          </pre>
        </Pulse>
        {!hide && <button onClick={() => setHide(true)}>Run</button>}
      </p>

      <p>We can also listen on URL changes in the browser:</p>
      <p>
        <Pulse active={addListener}>
          <pre>
            <code class="language-javascript">{`window.addEventListener("popstate", () => {
    window.location.href = "/up-is-down#top";
  });
`}</code>
          </pre>
        </Pulse>
        {!addListener && (
          <button onClick={() => setAddListener(true)}>Run</button>
        )}
      </p>

      <p>
        Finally, press the <b>back button</b> in your browser to continue.
      </p>
    </Layout>
  );
};

export default BackIsForward;
